<template>
  <div>
    <div class="personal-headerImg">
      <div class="header-box">
        <img v-if="personalData.photo" @click="uploadHeaderimg" :src="Services.ossUpload + personalData.photo" />
        <img v-else @click="uploadHeaderimg" src="../assets/img/head_img.png" alt="" />
      </div>
      <div class="header-name">
        <div class="flex-center" style="color: #fff;">
          <div>{{ personalData.name }}</div>
          <div style="margin-left: 5px; border-radius: 4px; border: 1px solid #ffffff;font-size: 12px;padding: 0 5px;">{{ userInfo.version.name }}</div>
        </div>
        <span>{{ personalData.phone }}</span>
      </div>
    </div>
    <div class="personal-nav">
      <!-- <ul>
                <router-link to="/personal/index"><li><i class="iconfont">&#xe608;</i><span>个人资料</span></li></router-link>
                <router-link to="/personal/Collect"><li><i class="iconfont">&#xe67a;</i><span>我的收藏</span></li></router-link>
                <router-link to="/personal/referral"><li class="active"><i class="iconfont">&#xe600;</i><span>我的转诊</span></li></router-link>
                <router-link to="/personal/treated"><li><i class="iconfont">&#xe628;</i><span>我的接诊</span></li></router-link>
                <router-link to="/personal/greenChannel"><li v-if="isgreenChannel == 1"><i class="iconfont">&#xe612;</i><span>绿色通道</span></li></router-link>
                <router-link to="/personal/Message"><li><i class="iconfont">&#xe60f;</i><span>消息通知</span></li></router-link>
                <router-link to="/personal/Browsing"><li><i class="iconfont">&#xe60a;</i><span>浏览记录</span></li></router-link>
                <router-link to="/personal/resetPassword"><li><i class="iconfont">&#xe620;</i><span>修改密码</span></li></router-link>
            </ul> -->
      <!-- class="el-menu-vertical-demo" -->
      <el-menu default-active="2" @select="handleSelect">
        <el-menu-item index="1"> <i class="iconfont">&#xe608;</i><span slot="title">个人资料</span> </el-menu-item>
        <el-menu-item index="15"> <i class="iconfont">&#xe67a;</i><span slot="title">升级版本</span> </el-menu-item>
        <el-menu-item index="2"> <i class="iconfont">&#xe67a;</i><span slot="title">我的收藏</span> </el-menu-item>
        <el-menu-item index="11"> <i class="iconfont">&#xe62c;</i><span slot="title">测试记录</span> </el-menu-item>
        <!-- <el-menu-item index="3">
                    <i class="iconfont">&#xe600;</i><span slot="title">我的转诊</span>
                </el-menu-item>
                <el-menu-item index="4">
                    <i class="iconfont">&#xe628;</i><span slot="title">我的接诊</span>
                </el-menu-item> -->
        <el-menu-item index="13"> <i class="el-icon-truck"></i><span slot="title">地址管理</span> </el-menu-item>
        <el-menu-item index="14"> <i class="el-icon-s-finance"></i><span slot="title">账单管理</span> </el-menu-item>
        <!-- <el-submenu index="8">
                    <template slot="title">
                        <i class="el-icon-video-camera"></i><span>视频管理</span>
                    </template>
                    <el-menu-item-group index="8-1">
                        <el-menu-item index="8-1">单视频管理</el-menu-item>
                        <el-menu-item index="8-2">视频合集管理</el-menu-item>
                    </el-menu-item-group>
                </el-submenu> -->
        <el-menu-item v-if="isgreenChannel == 1" index="9"> <i class="iconfont">&#xe612;</i><span>绿色通道</span> </el-menu-item>
        <!-- <el-menu-item index="9">
                    <i class="iconfont">&#xe628;</i><span slot="title">患者管理</span>
                </el-menu-item> -->
        <!-- <el-menu-item index="10">
                   <i class="iconfont">&#xe628;</i><span slot="title">处方管理</span>
                </el-menu-item> -->
        <!-- <el-submenu index="10">
                    <template slot="title">
                        <i class="el-icon-video-camera"></i><span>处方管理</span>
                    </template>
                    <el-menu-item-group index="10-1">
                        <el-menu-item index="10-1">待审查</el-menu-item>
                        <el-menu-item index="10-2">待取药</el-menu-item>
                        <el-menu-item index="10-3">已完成</el-menu-item>
                    </el-menu-item-group>
                </el-submenu> -->

        <el-menu-item index="5"> <i class="iconfont">&#xe60f;</i><span slot="title">消息通知</span> </el-menu-item>
        <el-menu-item index="6"> <i class="iconfont">&#xe60a;</i><span slot="title">浏览记录</span> </el-menu-item>
        <el-menu-item index="7"> <i class="iconfont">&#xe620;</i><span slot="title">修改密码</span> </el-menu-item>
      </el-menu>
    </div>
    <el-dialog :visible.sync="dialogVisible" title="上传头像" width="30%">
      <el-form class="formBox">
        <el-form-item>
          <el-upload :headers="headers" :action="FileUploadUrl" class="avatar-uploader" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" style="text-align: center">
            <img v-if="imageUrl" :src="Services.ossUpload + imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="savePhoto">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserInfo, userInfoLoad } from "../utils/userInfo";
import storage from "../utils/customstorage";
import { UserInfo } from "../components/User/UserInfo";
export default {
  name: "headerImg",
  inject: ["reload"],
  computed: {
    headers() {
      return {
        Authorization: "Bearer" + " " + storage.getItem("token"),
      };
    },
  },
  data() {
    var user = new UserInfo(this.TokenClient, this.Services.Authorization);
    return {
      imageUrl: "",
      personalData: {},
      isgreenChannel: this.$store.state.localData.userInfo.isgreenChannel,
      dialogVisible: false,
      FileUploadUrl: `${this.Services.Statics}/api/upload`,
      userDomain: user,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
    };
  },
  mounted() {
    this.personalData = getUserInfo();
  },
  methods: {
    getUserVersion(){
      this.userInfo=JSON.parse(sessionStorage.getItem("userInfo"))
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
      if (key == "1") {
        // 个人资料
        this.$router.push("/personal/index");
      } else if (key == "2") {
        // 我的收藏
        this.$router.push("/personal/Collect");
      } else if (key == "3") {
        // 我的转诊
        this.$router.push("/personal/referral");
      } else if (key == "4") {
        // 我的接诊
        this.$router.push("/personal/treated");
      } else if (key == "5") {
        // 消息通知
        this.$router.push("/personal/Message");
      } else if (key == "6") {
        // 浏览记录
        this.$router.push("/personal/Browsing");
      } else if (key == "7") {
        // 修改密码
        this.$router.push("/personal/resetPassword");
      } else if (key == "9") {
        // 患者管理
        this.$router.push("/patienTmanagement/patientList");
      } else if (key == "10-1") {
        // 待审核管理
        this.$router.push("/patienTmanagement/prescriptList");
      } else if (key == "10-2") {
        // 处方管理
        this.$router.push("/patienTmanagement/stateprescriptList");
      } else if (key == "10-3") {
        // 处方管理
        this.$router.push("/patienTmanagement/successprescriptList");
      } else if (key == "8-1") {
        // 单视频管理
        this.$router.push("/video/videoList");
      } else if (key == "8-2") {
        this.$router.push("/video/collectList");
        // 视频合集管理
      } else if (key == "8-3") {
        // 视频销售统计
      } else if (key == "11") {
        // 绿色通道
        this.$router.push("/personal/SelfTest");
      } else if (key == "13") {
        // 地址收货
        this.$router.push("/personal/adressList");
      } else if (key == "14") {
        // 地址收货
        this.$router.push("/personal/personalMoney");
      } else if (key == "15") {
        // 升级
        this.$router.push("/personal/upgrade");
      } else {
        // 绿色通道
        this.$router.push("/personal/greenChannel");
      }
    },
    uploadHeaderimg() {
      this.dialogVisible = true;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = `${res[0].completeFilePath}`;
      this.imageUrlData = `${res[0].completeFilePath}`;
    },
    beforeAvatarUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    savePhoto: function () {
      var _this = this;
      var editeItem = _this.personalData;
      _this.userDomain.Edit(editeItem.id, editeItem.name, editeItem.phone, editeItem.userTitleId, editeItem.userPostId, editeItem.roleId, editeItem.organizationId != 0 ? editeItem.organizationId : null, editeItem.departmentId != 0 ? editeItem.departmentId : null, editeItem.telephone, editeItem.address, editeItem.abstract, _this.imageUrlData, function (data) {
        _this.$message({
          type: "success",
          message: "操作成功!",
        });
        _this.personalData.photo = _this.imageUrl;
        _this.$bus.$emit("userhead", _this.imageUrl);
        userInfoLoad(_this.personalData.name, _this.personalData.phone, _this.personalData.titleId, _this.personalData.postId, _this.personalData.telephone, _this.personalData.address, _this.personalData.abstract, _this.imageUrlData);
        _this.dialogVisible = false;
        _this.personalData = getUserInfo();
      });
    },
  },
};
</script>

<style scoped>
::v-deep .el-menu-item .el-icon-truck {
  margin-right: 0px !important;
  width: 16px !important;
}
::v-deep .el-menu-item .el-icon-s-finance {
  margin-right: 0px !important;
  width: 16px !important;
}

.personal-headerImg {
  width: 260px;
  background: #1fb7a6;
  padding: 3px;
  border-radius: 5px;
  box-sizing: border-box;
}

.personal-headerImg .header-box {
  width: 254px;
  height: 205px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #fafffe;
  overflow: hidden;
}

.personal-headerImg img {
  width: 100%;
  height: 100%;
}

.header-name {
  padding: 10px;
}

.header-name p {
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
}

.header-name span {
  display: block;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}

.personal-nav {
  margin-top: 20px;
  width: 260px;
  background: #ffffff;
  padding: 20px;
  box-sizing: border-box;
}

.personal-nav /deep/ .el-submenu__title {
  padding: 0 !important;
  background: none;
  color: #ffffff;
  margin-top: 7px;
}

.personal-nav ul li {
  border-bottom: 1px solid #e5e5e5;
  line-height: 48px;
  padding-left: 5px;
  cursor: pointer;
}

.personal-nav ul li i {
  font-size: 16px;
  color: #737373;
}

.personal-nav ul li span {
  margin-left: 5px;
  font-size: 15px;
  color: #737373;
}

.personal-nav ul .router-link-active li {
  background: #1fb7a6;
  line-height: 54px;
  border-radius: 10px;
  padding-left: 15px;
}

.personal-nav ul .router-link-active li i,
.personal-nav ul .router-link-active li span {
  color: #ffffff !important;
}

.personal-nav ul li:hover i,
.personal-nav ul li:hover span {
  color: #1fb7a6;
}
.formBox /deep/ .el-upload--text {
  width: 178px;
  height: 178px;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
